import './FAQs.scss';

import { ReactNode, useMemo } from 'react';
import React from 'react';

import { AccordionList } from '../AccordionList/AccordionList';

export type FAQsItem = {
  question: string;
  answer: ReactNode | FAQsItem[];
};

type Props = {
  faqsItems: FAQsItem[];
};

const className = 'c-FAQs';

const faqsToAccordionItems = (faqsItems: FAQsItem[]) => {
  return faqsItems.map(({ question, answer }: FAQsItem) => {
    const isContentAnFAQ = React.isValidElement(answer);
    const childContent = isContentAnFAQ ? (
      <div className={`${className}__faq-answer`}>{answer}</div>
    ) : (
      <div className={`${className}__faq-nested`}>
        <FAQs faqsItems={answer as FAQsItem[]} />
      </div>
    );

    return {
      key: question,
      label: <span className={`${className}__faq-question`}>{question}</span>,
      content: childContent,
    };
  });
};

const FAQs = ({ faqsItems }: Props) => {
  const className = 'c-FAQs';

  const faqsAccordions = useMemo(() => {
    return faqsToAccordionItems(faqsItems);
  }, [faqsItems]);

  return (
    <div className={className}>
      <AccordionList items={faqsAccordions} variant="text" />
    </div>
  );
};

export default FAQs;
